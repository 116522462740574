<template>
  <div class="reset-container">
    <div @click="query" class="query">返回查詢</div>
    <div v-show="!resetted" class="reset">
      <input ref="email" @keyup="clearMessage" v-focus="!resetting" class="input" type="text" placeholder="請在此輸入登入帳號 username@lilithraws.org">
      <div ref="submit" @click="reset" class="submit" :class="{ disabled: resetting }">重置</div>
      <div ref="message" class="message"></div>
    </div>
    <div v-show="resetted" class="result">
      <div>授權碼已重置，請查看帳號信箱</div>
      <div @click="close" class="close">返回查詢</div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  /* eslint-disable no-useless-escape */
  name: 'Reset',
  data() {
    return {
      resetting: false,
      resetted: false
    }
  },
  methods: {
    query() {
      this.$store.dispatch('setReset', false);
    },
    reset() {
      if(this.resetting) return false;
      this.resetting = true;
      let emailreg = /^[a-z0-9]+[\._]?[a-z0-9]+[@](lilithraws\.org)$/;
      let email = this.$refs.email.value;
      if(emailreg.test(email)) {
        this.$refs.message.innerHTML = `重置中請稍候`;
        axios
          .get('https://api.lilithraws.org/v1/refreshToken', {
            params: {
              email,
            }
          })
          .catch(() => {
            this.$refs.message.innerHTML = `伺服器故障，請聯繫開發者`;
          })
          .then(response => {
            if(response.data.code == 200) {
              this.resetted = true;
            }else if(response.data.code == 403) {
              this.$refs.message.innerHTML = `此Email無效`;
            }else if(response.data.code == 404) {
              this.$refs.message.innerHTML = `此Email不存在於資料庫內`;
            }else if(response.data.code == 429) {
              this.$refs.message.innerHTML = `一個帳號在一小時內只能重置一次`;
            }else if(response.data.code == 500) {
              this.$refs.message.innerHTML = `伺服器故障，請聯繫開發者`;
            }else {
              this.$refs.message.innerHTML = `重置發生錯誤，錯誤代碼：${response.data.code}`;
            }
          })
          .finally(() => {
            this.$refs.email.value = null;
            this.resetting = false;
          })
      }else {
        this.$refs.message.innerHTML = `Email格式錯誤`;
        this.resetting = false;
      }
    },
    clearMessage() {
      this.$refs.message.innerHTML = '';
    },
    close() {
      this.resetting = false;
      this.resetted = false;
      this.$refs.email.value = null;
      this.$refs.message.innerHTML = null;
      this.$nextTick(() => {
        this.$store.dispatch('setReset', false);
      })
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      },
      update: function(el, binding) {
        if (binding.value) {
          el.focus();
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/less/reset.module';
</style>
