<template>
  <div id="app">
    <div class="main-container">
      <Reset v-if="this.$store.state.reset" />
      <Query v-else />
    </div>
  </div>
</template>

<script>
import Query from './components/Query.vue'
import Reset from './components/Reset.vue'

export default {
  name: 'App',
  components: {
    Query,
    Reset
  }
}
</script>

<style lang="less">
@import './assets/less/main.module';
</style>
