<template>
  <div class="query-container">
    <div v-show="!queryed" @click="reset" class="reset">重置授權碼</div>
    <div v-show="!queryed" class="query">
      <input ref="token" @keyup="clearMessage" v-focus="!queryed" class="input" type="text" placeholder="請在此輸入授權碼">
      <div ref="submit" @click="query" class="submit" :class="{ disabled: querying }">查詢</div>
      <div ref="message" class="message"></div>
    </div>
    <div v-show="queryed" class="result">
      <div ref="email" class="email"></div>
      <div ref="start" class="start"></div>
      <div ref="payed" class="payed"></div>
      <div ref="next" class="next"></div>
      <div v-if="queryed" class="address">
        <p>USDT-TRC20 (Huobi) <span class="copy">TS4dPD7La4sRd7Ksk9ua49teSF6a1wE2KH</span></p>
        <p>USDT (OKEx) <span class="copy">partment@live.com</span></p>
        <p>USDT-TRC20 (Bitopro) <span class="copy">TXi3CSsJFYgQER8edeu1kdmJPoxBypTkVP</span></p>
      </div>
      <div @click="close" class="close">關閉</div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Query',
  data() {
    return {
      querying: false,
      queryed: false,
    }
  },
  methods: {
    reset() {
      this.$store.dispatch('setReset', true);
    },
    query() {
      if(this.querying) return false;
      this.querying = true;
      let tokenreg = /^[a-z\d]{64}$/;
      let token = this.$refs.token.value;
      if(tokenreg.test(token)) {
        this.$refs.message.innerHTML = `查詢中請稍候`;
        axios
          .get('https://api.lilithraws.org/v1/getPaymentStatus', {
            params: {
              token,
            }
          })
          .catch(() => {
            this.$refs.message.innerHTML = `伺服器故障，請聯繫開發者`;
          })
          .then(response => {
            if(response.data.code == 200) {
              let result = response.data;
              this.$refs.email.innerHTML = `帳號：${result.email}`;
              this.$refs.start.innerHTML = `開始使用月份：${result.start_year}-${result.start_month}`;
              this.$refs.payed.innerHTML = `最後可用月份：${result.payed_year}-${result.payed_month}`;
              let next_year = parseInt(result.payed_year);
              let next_month = parseInt(result.payed_month);
              if(next_month == 12) {
                next_month = 0;
                next_year = next_year + 1;
              }
              let formattedMonth = (parseInt(next_month)+1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
              });
              this.$refs.next.innerHTML = `下次付費期限：${next_year}-${formattedMonth}-07`;
              this.queryed = true;
            }else if(response.data.code == 403) {
              this.$refs.message.innerHTML = `此授權碼無效，請重置`;
            }else {
              this.$refs.message.innerHTML = `查詢發生錯誤，錯誤代碼：${response.data.code}`;
            }
          })
          .finally(() => {
            this.querying = false;
          })
      }else {
        this.$refs.message.innerHTML = `授權碼格式錯誤`;
        this.querying = false;
      }
    },
    clearMessage() {
      this.$refs.message.innerHTML = '';
    },
    close() {
      this.querying = false;
      this.$refs.token.value = null;
      this.$refs.message.innerHTML = null;
      this.$nextTick(() => {
        this.queryed = false;
      })
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      },
      update: function(el, binding) {
        if (binding.value) {
          el.focus();
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/less/query.module';
</style>
