import * as types from './mutation-types'

export const state = {
  reset: false,
}

export const mutations = {
  [types.SET_RESET] (state, payload) {
    state.reset = payload;
  }
}

export const actions = {
  async setReset({commit}, payload){
    commit(types.SET_RESET, payload);
  }
}
